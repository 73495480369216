<script setup lang="ts">
import {ComputedRef, ref} from "vue";
import BaseLineTabChart from "~/components/Analytics/BaseLineTabChart.vue";
import {useAnalytics} from "~/store/analytics";
import {useFlag} from "~/plugins/flagsmith";

const analyticsStore = useAnalytics();

const granularity = computed(() => analyticsStore.getGranularity);
const from = computed(() => analyticsStore.getRangeFromAsIsoString);
const to = computed(() => analyticsStore.getRangeToAsIsoString);

const selectedChartType = ref('revenue');
const onChartTypeChange = (type: string) => {
  selectedChartType.value = type;
}

const offerBasicFeature = useFlag('offer.basic');

type Result = {
  date: string;

  // required for other
  total_count?: number;
  previous_count?: number;
  change?: number;

  // required only for revenue series
  revenue?: number;
  currency_code?: string;
  tax?: number;
  gross?: number;
}[];

const { data: resultRevenue, execute: executeRevenue, pending: pendingRevenue }: any|{result: Result} = await useFetch('/api/v1/analytics/series/revenue', {
  query: {
    from,
    to,
    granularity,
  },
});

const { data: resultExpectedRevenue, execute: executeExpectedRevenue, pending: pendingExpectedRevenue }: any|{result: Result} = await useFetch('/api/v1/analytics/series/expectedRevenue', {
  query: {
    from,
    to,
    granularity,
  },
});

const { data: resultCustomer, execute: executeCustomer, pending: pendingCustomer }: any|{result: Result} = await useFetch('/api/v1/analytics/series/customer', {
  query: {
    from,
    to,
    granularity,
  },
});

const { data: resultSubscription, execute: executeSubscription, pending: pendingSubscription }: any|{result: Result} = await useFetch('/api/v1/analytics/series/subscription', {
  query: {
    from,
    to,
    granularity,
  },
});

const { data: resultOfferSigned, execute: executeOfferSigned, pending: pendingOfferSigned }: any|{result: Result} = await useFetch('/api/v1/analytics/series/signed-offer', {
  query: {
    from,
    to,
    granularity,
  },
  immediate: offerBasicFeature.value,
});

const { data: resultOfferOpen, execute: executeOfferOpen, pending: pendingOfferOpen }: any|{result: Result} = await useFetch('/api/v1/analytics/series/open-offer', {
  query: {
    from,
    to,
    granularity,
  },
  immediate: offerBasicFeature.value,
});

const offerPending = computed(() => {
  if (!offerBasicFeature.value) {
    return false;
  }
  return pendingOfferSigned?.value || pendingOfferOpen?.value;
});

const pending = computed(() => {
  return pendingRevenue?.value || pendingCustomer?.value || pendingSubscription?.value || offerPending.value || pendingExpectedRevenue?.value;
});

onMounted(async () => {
  executeRevenue();
  executeCustomer();
  executeSubscription();
  executeExpectedRevenue();
  if (offerBasicFeature.value) {
    executeOfferSigned();
    executeOfferOpen();
  }
});

const getCurrentMonthValues = (values: Result) => {
  return values?.filter((item) => {
    // month and year is equal
    return new Date(item.date).getMonth() === new Date().getMonth() && new Date(item.date).getFullYear() === new Date().getFullYear();
  }).filter(item => !item?.currency_code ? true : item.currency_code === analyticsStore.getCurrency);
}

const getPreviousMonthValues = (values: Result) => {
  return values?.filter((item) => {
    // if the month is 1, we have to check the previous year and month 12
    if (new Date().getMonth() === 0) {
      return new Date(item.date).getMonth() === 12 && new Date(item.date).getFullYear() === new Date().getFullYear() - 1;
    }

    return new Date(item.date).getMonth() === new Date().getMonth() - 1 && new Date(item.date).getFullYear() === new Date().getFullYear();
  }).filter(item => !item?.currency_code ? true : item.currency_code === analyticsStore.getCurrency);
}

const tabs: ComputedRef<{
  id: 'revenue' | 'customer' | 'subscription' | 'offer';
  label: string;
  value: string;
  lastPeriodValue: string;
  percentageChange: number;
  reverseColor?: boolean;
}[]> = computed(() => {
  const revenue = getCurrentMonthValues(resultRevenue.value)?.reduce((acc, item) => acc + parseFloat(item.revenue), 0) || 0;
  const customers = getCurrentMonthValues(resultCustomer.value)?.reduce((acc, item) => acc + parseFloat(item.total_count), 0) || 0;
  const subscriptions = getCurrentMonthValues(resultSubscription.value)?.reduce((acc, item) => acc + parseFloat(item.total_count), 0) || 0;
  const offers = getCurrentMonthValues(resultOfferSigned.value)?.reduce((acc, item) => acc + parseFloat(item.total_count), 0) || 0;

  const lastPeriodRevenue = getPreviousMonthValues(resultRevenue.value)?.reduce((acc, item) => acc + parseFloat(item.revenue), 0) || 0;
  const lastCustomers = getPreviousMonthValues(resultCustomer.value)?.reduce((acc, item) => acc + parseFloat(item.total_count), 0) || 0;
  const lastSubscriptions = getPreviousMonthValues(resultSubscription.value)?.reduce((acc, item) => acc + parseFloat(item.total_count), 0) || 0;
  const lastOffers = getPreviousMonthValues(resultOfferSigned.value)?.reduce((acc, item) => acc + parseFloat(item.total_count), 0) || 0;

  const revenuePercentageChange = Math.round(((revenue - lastPeriodRevenue) / lastPeriodRevenue) * 100) || 0;
  const lastCustomersPercentageChange = Math.round(((customers - lastCustomers) / lastCustomers) * 100) || 0;
  const lastSubscriptionsPercentageChange = Math.round(((subscriptions - lastSubscriptions) / lastSubscriptions) * 100) || 0;
  const lastOffersPercentageChange = Math.round(((offers - lastOffers) / lastOffers) * 100) || 0;

  const formatValue = (value: number) => {
    return new Intl.NumberFormat('de-DE', { style: 'currency', currency: analyticsStore.getCurrency }).format(value);
  }

  return [
    {
      id: 'revenue',
      label: 'Umsatz (netto)',
      value: formatValue(revenue),
      lastPeriodValue: formatValue(lastPeriodRevenue) ,
      percentageChange: revenuePercentageChange,
      help: 'Der Umsatz wird auf Basis der bezahlten Netto-Rechnungen ermittelt.',
    },
    {
      id: 'customer',
      label: 'Kunden',
      value: customers,
      lastPeriodValue: lastCustomers,
      percentageChange: lastCustomersPercentageChange,
    },
    {
      id: 'subscription',
      label: 'Aktive Abonnements',
      value: subscriptions,
      lastPeriodValue: lastSubscriptions,
      percentageChange: lastSubscriptionsPercentageChange,
    },
    {
      id: 'offer',
      label: 'Akzeptierte Angebote',
      value: offers,
      lastPeriodValue: lastOffers,
      percentageChange: lastOffersPercentageChange,
      hidden: !offerBasicFeature.value,
    },
  ]
})

const revenueDatasetsFactory = () => {
  const groupedByCurrencies = resultRevenue.value?.reduce((acc, item) => {
    if (!acc[item.currency_code]) {
      acc[item.currency_code] = [];
    }

    acc[item.currency_code].push(item);

    return acc;
  }, {}) || {};

  const expectedGroupedByCurrencies = resultExpectedRevenue.value?.reduce((acc, item) => {
    if (!acc[item.currency_code]) {
      acc[item.currency_code] = [];
    }

    acc[item.currency_code].push(item);

    return acc;
  }, {}) || {};

  let datasets = [];

  const dates = resultRevenue.value?.map((item) => item.date.split('T')[0]) || [];
  // remove duplicates
  const uniqueDates = [...new Set(dates)];


  Object.keys(groupedByCurrencies).forEach((currency, index) => {
    const dataItems = groupedByCurrencies[currency];
    const data = [];

    // fill gaps in data
    for (let i = 0; i < uniqueDates.length; i++) {
      const date = new Date(uniqueDates[i]);
      const found = dataItems.find((item) => {
        const itemDate = new Date(item.date);
        return itemDate.getMonth() === date.getMonth() && itemDate.getFullYear() === date.getFullYear();
      });

      if (found) {
        data.push(found);
      } else {
        data.push({
          date: date.toISOString(),
          currency_code: currency,
          revenue: 0,
          tax: 0,
          gross: 0,
        });
      }
    }

    const suffix = Object.keys(groupedByCurrencies).length > 1 ? ` (${currency})` : '';

    datasets.push({
      type: 'line',
      currencyCode: currency,
      label: 'Netto' + suffix,
      data: data.map((item) => {
        return { value: parseFloat(item.revenue), date: item.date };
      }) || [],
    });

    datasets.push({
      type: 'line',
      currencyCode: currency,
      label: 'Brutto' + suffix,
      pointBackgroundColor: '#294656',
      borderColor: '#294656',
      backgroundColor: (ctx: any) => {
        return 'rgba(69, 205, 57, 0)';
      },
      data: data.map((item) => {
        return { value: parseFloat(item.gross), date: item.date };
      }) || [],
    });
  });

  Object.keys(expectedGroupedByCurrencies).forEach((currency, index) => {
    const dataItems = expectedGroupedByCurrencies[currency];
    const data = [];

    // fill gaps in data
    for (let i = 0; i < uniqueDates.length; i++) {
      const date = new Date(uniqueDates[i]);
      const found = dataItems.find((item) => {
        const itemDate = new Date(item.date);
        return itemDate.getMonth() === date.getMonth() && itemDate.getFullYear() === date.getFullYear();
      });

      if (found) {
        data.push(found);
      } else {
        data.push({
          date: date.toISOString(),
          currency_code: currency,
          revenue: 0,
          tax: 0,
          gross: 0,
        });
      }
    }

    const suffix = Object.keys(expectedGroupedByCurrencies).length > 1 ? ` (${currency})` : '';

    datasets.push({
      type: 'line',
      currencyCode: currency,
      label: 'Netto erwartet ' + suffix,
      data: data.map((item) => {
        return { value: parseFloat(item.revenue), date: item.date };
      }) || [],
      // blue color
      pointBackgroundColor: '#3B82F6',
      borderColor: '#3B82F6',
      backgroundColor: (ctx: any) => {
        return 'rgba(69, 205, 57, 0)';
      },
    });

    datasets.push({
      type: 'line',
      currencyCode: currency,
      label: 'Brutto erwartet ' + suffix,
      // dark blue (darker then above)
      pointBackgroundColor: '#2563EB',
      borderColor: '#2563EB',
      backgroundColor: (ctx: any) => {
        return 'rgba(69, 205, 57, 0)';
      },
      data: data.map((item) => {
        return { value: parseFloat(item.gross), date: item.date };
      }) || [],
    });
  });

  return datasets;
}

const customerDatasetsFactory = () => {
  return [
    {
      type: 'line',
      label: 'Anzahl Kunden',
      data: resultCustomer.value.map((item) => {
        return { value: parseFloat(item.total_count), date: item.date };
      }) || [],
    }
  ];
}

const subscriptionDatasetsFactory = () => {
  return [
    {
      type: 'line',
      label: 'Aktive Abonnements',
      data: resultSubscription.value.map((item) => {
        return { value: parseFloat(item.total_count), date: item.date };
      }) || [],
    }
  ];
}

const fillEmptyDates = (data: any) => {
  // get all dates based on granularity and from - to
  const dates = [];
  const fromDate = new Date(from.value);
  const toDate = new Date(to.value);

  // respect timezones..

  if (granularity.value === 'monthly') {
    let currentDate = fromDate;
    while (currentDate <= toDate) {
      dates.push(currentDate.toISOString().split('T')[0]);
      currentDate = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
    }
  }

  if (granularity.value === 'yearly') {
    let currentDate = fromDate;
    while (currentDate <= toDate) {
      dates.push(currentDate.toISOString().split('T')[0]);
      currentDate = new Date(currentDate.setFullYear(currentDate.getFullYear() + 1));
    }
  }

  if (granularity.value === 'daily') {
    let currentDate = fromDate;
    while (currentDate <= toDate) {
      dates.push(currentDate.toISOString().split('T')[0]);
      currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
    }
  }

  if (granularity.value === 'weekly') {
    let currentDate = fromDate;
    while (currentDate <= toDate) {
      dates.push(currentDate.toISOString().split('T')[0]);
      currentDate = new Date(currentDate.setDate(currentDate.getDate() + 7));
    }
  }

  return dates.map((date) => {
    const found = data.find((item) => {
      console.log('item.date', item.date, 'date', date);
      return new Date(item.date) === new Date(date);
    });

    console.log('found', found);
    if (found) {
      return found;
    }

    return {
      date,
      total_count: 0,
    };
  });
}

const offerDatasetsFactory = computed(() => {
  return [
    {
      type: 'bar',
      label: 'Offene Angebote',
      backgroundColor: '#FFA500',
      data: resultOfferOpen.value.map((item) => {
        return { value: parseFloat(item.total_count), date: item.date };
      }) || [],
    },
    {
      type: 'bar',
      label: 'Unterschriebene Angebote',
      backgroundColor: '#008000',
      data: resultOfferSigned.value.map((item) => {
        return { value: parseFloat(item.total_count), date: item.date };
      }) || [],
    }
  ];
});

const datasets = computed(() => {
  if (selectedChartType.value === 'revenue') {
    return revenueDatasetsFactory();
  }

  if (selectedChartType.value === 'customer') {
    return customerDatasetsFactory();
  }

  if (selectedChartType.value === 'subscription') {
    return subscriptionDatasetsFactory();
  }

  if (selectedChartType.value === 'offer') {
    return offerDatasetsFactory.value;
  }

  return []
})

const formatLabel = (rawValue: any, dataset: any) => {
  let value = rawValue;
  if (dataset?.currencyCode) {
    value = new Intl.NumberFormat('de-DE', { style: 'currency', currency: dataset.currencyCode }).format(rawValue);
  }

  return `${dataset.label}: ${value}`;
}
</script>

<template>
  <BaseLineTabChart :tabs="tabs" :granularity="granularity" :pending="pending" :result="result" @chart-type-change="onChartTypeChange" :datasets="datasets" :format-label="formatLabel" />
</template>
