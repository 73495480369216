import { defineStore } from 'pinia';

export const useAnalytics = defineStore('analytics', {
  state: () => ({
    dateRange: [
      // beginning of this year
      new Date(new Date().getFullYear(), 0, 1),
      // end of this year
      new Date(new Date().getFullYear(), 12, 0)
    ],
    granularity: 'monthly',
    currency: 'EUR'
  }),
  actions: {
    async setRange(range: Date[]) {
      this.dateRange = range;
    },
    async setGranularity(granularity: 'daily' | 'weekly' | 'monthly' | 'yearly') {
      this.granularity = granularity;
    }
  },
  getters: {
    getRangeFromAsIsoString: (state) => {
      return state.dateRange[0].toISOString();
    },
    getRangeToAsIsoString: (state) => {
      return state.dateRange[1].toISOString();
    },
    getGranularity: (state) => {
      return state.granularity;
    },
    getCurrency: (state) => {
      return state.currency;
    },
    getRange: (state) => {
      return state.dateRange;
    }
  },
});
