<template>
  <!--<button @click="addNewWidget()">Add Widget</button> {{ info }}-->

  <section class="grid-stack">
    <div
        v-for="(component, key, index) in components"
        :key="'component'+index"
        :gs-id="key"
        class="grid-stack-item"
        :gs-x="component.gridPos.x"
        :gs-y="component.gridPos.y"
        :gs-h="component.gridPos.h"
        :gs-w="component.gridPos.w"
        gs-auto-position="true"
    >
      <div class="grid-stack-item-content">
        <component :is="component.name" v-bind="component.props"/>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import {ref, onMounted, reactive, nextTick} from 'vue';
import 'gridstack/dist/gridstack.min.css';
import {GridStack} from 'gridstack';
import InvoiceOverDueReport from "~/components/Invoice/InvoiceOverDueReport.vue";
import NewSepaExportsCount from "~/components/Analytics/NewSepaExportsCount.vue";
import ApprovalInvoicesCount from "~/components/Analytics/ApprovalInvoicesCount.vue";
import InvoiceOverDueCard from "~/components/Analytics/InvoiceOverDueCard.vue";
import NewOrdersCount from "~/components/Analytics/NewOrdersCount.vue";
import NewRevenueCount from "~/components/Analytics/NewRevenueCount.vue";
import NewCustomerCount from "~/components/Analytics/NewCustomerCount.vue";
import AcceptedOffersCount from "~/components/Analytics/AcceptedOffersCount.vue";
import RevenueBarChart from "~/components/Analytics/RevenueBarChart.vue";
import ProductDonutChart from "~/components/Analytics/ProductDonutChart.vue";
import RevenueTabChart from "~/components/Analytics/RevenueTabChart.vue";
import CountSeriesTabChart from "~/components/Analytics/CountSeriesTabChart.vue";
import CountSeriesTabChartSuspense from "~/components/Analytics/CountSeriesTabChartSuspense.vue";


const props = defineProps({
  gridEditable: {
    type: Boolean,
    required: false,
    default: false,
  },
})

let info = ref("");
let grid: GridStack | null = null;
const message = useMessage();

const componentIdToName = markRaw({
  'invoiceOverDueReport': InvoiceOverDueCard,
  'newSepaExportsCount': NewSepaExportsCount,
  'approvalInvoiceCount': ApprovalInvoicesCount,
  'countSeriesTabChart': CountSeriesTabChart,
  'recurringRevenueTabChart': RevenueTabChart,
  'productDonutChart': ProductDonutChart,
});

const defaultComponents = shallowRef({
  invoiceOverDueReport: {
    name: InvoiceOverDueCard, props: {}, gridPos: {x: 0, y: 1, w: 9, h: 8}, noResize: true
  },
  newSepaExportsCount: {
    name: NewSepaExportsCount, props: {}, gridPos: {x: 9, y: 1, w: 3, h: 4}, noResize: true
  },
  approvalInvoiceCount: {
    name: ApprovalInvoicesCount, props: {}, gridPos: {x: 9, y: 4, w: 3, h: 4}, noResize: true
  },
  countSeriesTabChart: {
    name: CountSeriesTabChartSuspense, props: {}, gridPos: {x: 0, y: 5, w: 12, h: 16}, noResize: true
  },
  recurringRevenueTabChart: {
    name: RevenueTabChart, props: {}, gridPos: {x: 0, y: 19, w: 12, h: 16}, noResize: true
  },
  productDonutChart: {
    name: ProductDonutChart, props: {}, gridPos: {x: 0, y: 35, w: 6, h: 13}, noResize: true
  },
});

let components = shallowRef({});

const isLoading = ref(true);

const initGrid = () => {
  grid = GridStack.init({
    float: false,
    cellHeight: "40px",
    minRow: 1,
    disableResize: true,
    removable: true,
  });

  grid.on("dragstop", (event, element) => {
    const gridData = grid.save(false);

    $fetch('/api/v1/ui/grid', {
      method: 'POST',
      body: JSON.stringify(gridData),
      onResponse({response}) {
        if (response.status === 204) {
          message.success('Dashboard gespeichert');
        } else {
          message.error('Dashboard konnte nicht gespeichert werden');
        }
      }
    }).catch(() => {
      message.error('Dashboard konnte nicht gespeichert werden');
    });
  });

  watch(() => props.gridEditable, (newValue: boolean) => {
    grid?.enableMove(newValue, true);
  });

  grid?.enableMove(props.gridEditable, true);
}

// this will of course only work once because of the object-key
function addNewWidget() {
  // components.yourRandomComponent3= {
  //   name: "YourRandomComponent3", props: {}, gridPos: { x: 0, y: 1, w: 2, h: 5 }
  // };
  // // we have to wait for vue to update v-for,
  // // until then the querySelector wont find the element
  // nextTick(() => {
  //   console.log(grid);
  //   let compEl = document.querySelector('[gs-id="yourRandomComponent3"]');
  //   console.log(compEl);
  //   grid.makeWidget(compEl);
  // });
  // console.warn("i will only work once, fix my inputs to reuse me");
}

onMounted(async () => {
  await $fetch('/api/v1/ui/grid', {
    onResponse({response}) {
      if (response.status === 200) {
        if (response._data.length > 0 && typeof response._data === 'object') {
          const newComponents = {};
          response._data.forEach((item) => {
            newComponents[item.id] = {
              name: componentIdToName[item.id],
              props: {},
              gridPos: item,
              noResize: true,
            };
          });

          components.value = newComponents;
          isLoading.value = false;
        } else {
          components.value = defaultComponents.value;
          isLoading.value = false;
        }
      }
    }
  })

  initGrid();
});
</script>

<style>
.grid-stack {
  margin-left: -1rem;
  margin-right: 0.5rem;
  margin-bottom: 0.75rem;
}

.grid-stack-item {
  z-index: 50;
}

.grid-stack-item > .grid-stack-item-content {
  @apply h-full;
  margin: 0.35rem !important;
  inset: 0.75rem !important;
  position: relative !important;
  padding-bottom: 0.75rem !important;

  > div {
    height: 100%;
  }

  .card {
    @apply h-full;
  }
}

.grid-stack-item:not(.ui-draggable-disabled) {
  .card-header, .card-title, .card > div:first-child {
    @apply cursor-move;
  }
}

.grid-stack > .grid-stack-item > .grid-stack-item-content {
  overflow: initial;
}
</style>
