<script setup lang="ts">
import {type ComputedRef, ref} from "vue";
import BaseLineTabChart from "~/components/Analytics/BaseLineTabChart.vue";
import {useAnalytics} from "~/store/analytics";

const analyticsStore = useAnalytics();

const granularity = computed(() => analyticsStore.getGranularity);
const from = computed(() => analyticsStore.getRangeFromAsIsoString);
const to = computed(() => analyticsStore.getRangeToAsIsoString);

const { data: result, execute, pending }: any|{result: {
  date: string;
  currency: string;
  revenue: number;
  churn_revenue: number;
  expansion_revenue: number;
  new_revenue: number;
  }[]} = await useFetch('/api/v1/analytics/subscription-revenue', {
  query: {
    from,
    to,
    granularity,
  },
});

onMounted(async () => {
  await execute();
});

const chartType = ref('mrr');
const onChartTypeChange = (type: string) => {
  chartType.value = type;
}

const tabs: ComputedRef<{
  id: 'mrr' | 'new_mrr' | 'expansion_mrr' | 'churn_mrr';
  label: string;
  value: string;
  lastPeriodValue: string;
  percentageChange: number;
  reverseColor?: boolean;
}[]> = computed(() => {
  const currentMonthValues = result.value?.filter((item) => {
    // month and year is equal
    return new Date(item.date).getMonth() === new Date().getMonth() && new Date(item.date).getFullYear() === new Date().getFullYear();
  }).filter(item => item.currency_code === analyticsStore.getCurrency);

  const previousMonthValues = result.value?.filter((item) => {
    // if the month is 1, we have to check the previous year and month 12
    if (new Date().getMonth() === 0) {
      return new Date(item.date).getMonth() === 12 && new Date(item.date).getFullYear() === new Date().getFullYear() - 1;
    }

    return new Date(item.date).getMonth() === new Date().getMonth() - 1 && new Date(item.date).getFullYear() === new Date().getFullYear();
  }).filter(item => item.currency_code === analyticsStore.getCurrency);

  const mrr = currentMonthValues?.reduce((acc, item) => acc + parseFloat(item.revenue), 0) || 0;
  const newMrr = currentMonthValues?.reduce((acc, item) => acc + parseFloat(item.new_revenue), 0) || 0;
  const extensionMrr = currentMonthValues?.reduce((acc, item) => acc + parseFloat(item.expansion_revenue), 0) || 0;
  const churnMrr = currentMonthValues?.reduce((acc, item) => acc + parseFloat(item.churn_revenue), 0) || 0;

  const lastPeriodMrr = previousMonthValues?.reduce((acc, item) => acc + parseFloat(item.revenue), 0) || 0;
  const lastPeriodNewMrr = previousMonthValues?.reduce((acc, item) => acc + parseFloat(item.new_revenue), 0) || 0;
  const lastPeriodExtensionMrr = previousMonthValues?.reduce((acc, item) => acc + parseFloat(item.expansion_revenue), 0) || 0;
  const lastPeriodChurnMrr = previousMonthValues?.reduce((acc, item) => acc + parseFloat(item.churn_revenue), 0) || 0;

  const mrrPercentageChange = Math.round(((mrr - lastPeriodMrr) / lastPeriodMrr) * 100) || 0;
  const newMrrPercentageChange = Math.round(((newMrr - lastPeriodNewMrr) / lastPeriodNewMrr) * 100) || 0;
  const extensionMrrPercentageChange = Math.round(((extensionMrr - lastPeriodExtensionMrr) / lastPeriodExtensionMrr) * 100) || 0;
  const churnMrrPercentageChange = Math.round(((churnMrr - lastPeriodChurnMrr) / lastPeriodChurnMrr) * 100) || 0;

  const formatValue = (value: number) => {
    return new Intl.NumberFormat('de-DE', { style: 'currency', currency: analyticsStore.getCurrency }).format(value);
  }

  return [
    {
      id: 'mrr',
      //label: 'Revenue',
      label: 'MRR',
      value: formatValue(mrr),
      lastPeriodValue: formatValue(lastPeriodMrr),
      percentageChange: mrrPercentageChange,
    },
    // new MRR
    {
      id: 'new_mrr',
      //label: 'New MRR',
      label: 'Neuer MRR',
      value: formatValue(newMrr),
      lastPeriodValue: formatValue(lastPeriodNewMrr),
      percentageChange: newMrrPercentageChange,
    },
    // extension MRR
    {
      id: 'expansion_mrr',
      //label: 'Extension MRR',
      label: 'Erweiterung MRR',
      value: formatValue(extensionMrr),
      lastPeriodValue: formatValue(lastPeriodExtensionMrr),
      percentageChange: extensionMrrPercentageChange,
    },
    // churn MRR
    {
      id: 'churn_mrr',
      label: 'Churn MRR',
      value: formatValue(churnMrr),
      lastPeriodValue: formatValue(lastPeriodChurnMrr),
      percentageChange: churnMrrPercentageChange,
      reverseColor: true,
    },
  ]
})

const datasets = computed(() => {
  const groupedByCurrencies = result.value.reduce((acc, item) => {
    if (!acc[item.currency_code]) {
      acc[item.currency_code] = [];
    }

    acc[item.currency_code].push(item);

    return acc;
  }, {});

  let datasets = [];

  const dates = result.value.map((item) => item.date.split('T')[0]);
  // remove duplicates
  const uniqueDates = [...new Set(dates)];


  Object.keys(groupedByCurrencies).forEach((currency, index) => {
    const dataItems = groupedByCurrencies[currency];
    const data = [];

    // fill gaps in data
    for (let i = 0; i < uniqueDates.length; i++) {
      const date = new Date(uniqueDates[i]);
      const found = dataItems.find((item) => {
        const itemDate = new Date(item.date);
        return itemDate.getMonth() === date.getMonth() && itemDate.getFullYear() === date.getFullYear();
      });

      if (found) {
        data.push(found);
      } else {
        data.push({
          date: date.toISOString(),
          currency_code: currency,
          revenue: 0,
          churn_revenue: 0,
          expansion_revenue: 0,
          new_revenue: 0,
        });
      }
    }

    const suffix = Object.keys(groupedByCurrencies).length > 1 ? ` (${currency})` : '';

    if (chartType.value === 'churn_mrr') {
      datasets.push({
        type: 'line',
        label: 'Churn MRR' + suffix,
        data: data.map((item) => {
          return { value: parseFloat(item.churn_revenue), date: item.date };
        }) || [],
      });
    }

    if (chartType.value === 'new_mrr') {
      datasets.push({
        type: 'line',
        label: 'Neuer MRR' + suffix,
        data: data.map((item) => {
          return { value: parseFloat(item.new_revenue), date: item.date };
        }) || [],
      });
    }

    if (chartType.value === 'expansion_mrr') {
      datasets.push({
        type: 'line',
        label: 'MRR durch Erweiterung' + suffix,
        data: data.map((item) => {
          return { value: parseFloat(item.expansion_revenue), date: item.date };
        }) || [],
      });
    }

    if (chartType.value === 'mrr') {
      datasets.push({
        type: 'line',
        label: 'MRR' + suffix,
        data: data.map((item) => {
          return { value: parseFloat(item.revenue), date: item.date };
        }) || [],
      });
    }

    // get last dataset and set currencyCode
    if (datasets.length > 0) {
      datasets[datasets.length - 1].currencyCode = currency;
    }
  });

  return datasets;
})

const formatLabel = (raw: any, { label, currencyCode }) => {
  // format by currency to locale
  const formatted = new Intl.NumberFormat('de-DE', { style: 'currency', currency: currencyCode }).format(raw);

  return `${label}: ${formatted}`;
}
</script>

<template>
  <BaseLineTabChart :tabs="tabs" :granularity="granularity" :pending="pending" :format-label="formatLabel" :datasets="datasets" @chart-type-change="onChartTypeChange" />
</template>
