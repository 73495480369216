<script setup lang="ts">

import BaseLineTabChartSkeleton from "~/components/Analytics/BaseLineTabChartSkeleton.vue";
import CountSeriesTabChart from "~/components/Analytics/CountSeriesTabChart.vue";
import {BaseLineTab} from "~/components/Analytics/BaseLineTabChart.vue";
import {type ComputedRef} from "vue";
import {useFlag} from "~/plugins/flagsmith";

const offerBasicFeature = useFlag('offer.basic');

const tabs: ComputedRef<BaseLineTab[]> = computed(() => {
  return [
    {
      label: 'Umsatz',
      id: 'revenue',
      skeleton: true,
      help: 'Der Umsatz wird aus den bezahlten Netto-Rechnungen berechnet.',
    },
    {
      label: 'Kunden',
      id: 'customer',
      skeleton: true,
    },
    {
      label: 'Aktive Abonnements',
      id: 'subscription',
      skeleton: true,
    },
    {
      label: 'Akzeptierte Aufträge',
      id: 'offer',
      skeleton: true,
      hidden: !offerBasicFeature.value,
    }
  ];
})
</script>

<template>
  <div>
    <Suspense>
      <template #fallback>
        <BaseLineTabChartSkeleton :tabs="tabs" />
      </template>

      <CountSeriesTabChart />

    </Suspense>
  </div>
</template>

<style scoped lang="scss">

</style>
